.nav-link,
.nav-item {
  font-size: 1.1rem !important;
  /* margin-right: 20px; */
  color: var(--white-color);
  text-transform: capitalize;
  transition: 0.5s;
}

.nav-item .nav-link {
  margin-right: 0px;
}


/*********************************************** marque *************************************************************/

.leftside {
  height: 50px;
}

.top-bar {
  color: #fff;
  background: #0c0fb3;
  font-size: 1.1rem;
}

.top-bar p {
  color: #fff;
}


.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  /* background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 10px 0; */
}

.marquee-content {
  display: inline-block;
  animation: marquee 50s linear infinite;
  padding-left: 100%;
  /* Start off-screen */
}

.marquee-container.paused .marquee-content {
  animation-play-state: paused;
}

/* Marquee animation */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

:focus-visible {
  outline: none !important;
}

.nav-link:last-child,
.nav-link:nth-child(3) {
  margin-right: 0px;
}

@media (min-width: 993px) {
  /* .nav-item.dropdown:hover .dropdown-menu {
  display: block !important;
}  */


  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
    /* transition: 1s !important; */
    /* margin-top: -15px !important; */
  }

}

.navbar-toggler {
  border: none;
  padding: 8px 10px;
  background-color: var(--main-color);
  color: var(--white-color);
  font-size: 1.4rem;
}

.navbar-toggler-icon {
  background-image: none;
}

.logo {
  width: 100px;
  height: auto;
}

/* .dropdown-item{
  padding: 0;
}
.dropdown-item a{
  height: 100%;
  width: 100%;
  padding: .25rem 1rem;
  display: inline-block;
}
.dropdown-item a:hover{
  color: var(--main-color) !important;
} */
.navbar-nav .nav-link.active,
.nav-link:focus,
.nav-link:hover,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item.active a,
.dropdown-item:active a,
.nav-link .dropdown-item a:hover {
  color: var(--white-color) !important;
  background-color: var(--main-color);
}

a {
  text-decoration: none !important;
  color: #000;
}

.nav-container {
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 5;
  transition: 0.5s;
}

.nav-container nav {
  background-color: #00000063;
}

.nav_links {
  width: 100%;
}

.offcanvas-body .nav-link {
  padding: 2px 10px !important;
  margin-right: 10px;
  border-radius: 0.25rem;
  transition: 0.3s;
  font-weight: 500;
  /* padding-right: 35px !important; */
}

.nav-sm-icon a {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.sticky-top {
  position: fixed !important;
  top: 0;
  background-color: var(--white-color) !important;
  z-index: 5;
  transition: 0.3 all ease-in-out;
  -webkit-transition: 0.3 all ease-in-out;
}

.sticky-top nav {
  background: var(--white-color) !important;
}

.sticky-top .header-content p {
  color: #000 !important;
}

.sticky-top .nav-link,
.sticky-top .nav-item {
  color: var(--black-color);
  font-weight: 500;
}

.nav-container nav {
  position: absolute !important;
  width: 100% !important;
  transition: 0.5s;
}

.shadow-sm nav {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.nav-top-links svg {
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: 0.25rem;
  color: var(--white-color);
  background-color: var(--main-color);
  margin-right: 2px;
}

.nav-top-links a {
  text-transform: none;
  padding: 0 !important;
}


.number-dwarka {
  cursor: none;
}

@media (min-width: 1100px) and (max-width: 1199px) {
  .offcanvas-body .nav-link {
    padding: 2px 5px !important;
  }
}

@media (min-width: 1030px) and (max-width: 1100px) {
  .offcanvas-body .nav-link {
    padding: 2px 5px !important;
    font-size: 1rem !important;
  }
}

@media (min-width: 993px) and (max-width: 1030px) {
  .offcanvas-body .nav-link {
    font-size: 0.9rem !important;
    margin-right: 5px;
  }
}

@media (max-width: 992px) {

  .nav-container nav,
  .nav-container {
    position: static !important;
    top: 0;
    /* border-bottom: 1px solid #dee2e6; */
    background-color: var(--white-color) !important;
  }

  .sticky-top {
    position: fixed !important;
    transition: 0.3 all ease-in-out;
    -webkit-transition: 0.3 all ease-in-out;
  }

  .nav-sm-icon a {
    margin-right: 5px;
    font-size: 1.5rem;
  }

  .offcanvas-body .nav-link {
    padding-left: 5px !important;
  }

  .nav-link,
  .nav-item {
    color: var(--black-color);
    margin-bottom: 5px;
  }

  .navbar-toggler {
    position: absolute;
    left: 10px;
  }

  .navbar {
    padding: 0;
  }

  .dropdown-menu {
    border: none;
  }

  .nav-link-btn {
    display: inline-block;
    width: 100%;
    /* margin: 0 auto; */
    margin-top: 1.1rem;
    text-align: center;
  }

  .navbar-nav .dropdown-menu {
    padding-left: 0.5rem;
  }
}

@media (max-width:568px) {
  .nav-top-links .nav-link {
    background-color: var(--main-color);
    color: var(--white-color);
  }
}

@media (max-width:900px) {
  .header-content {
    position: absolute;
    top: 50%;
    left: 43%;
    transform: translate(-45%, -50%);
  }
}