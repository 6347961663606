.footer-logo {
  width: 100px;
}
.footer-section ul {
  margin: 0;
  padding: 0;
}

.footer-section ul li a:hover{
    color: var(--main-color);
}
.contact-links li a {
  font-size: 2rem;
}








.footer-section.white-bg .single-footer-widget h2 {
  color: var(--second-main-color);
}

.footer-section.white-bg {
  background-color: #000 ;
}

.footer-section.white-bg .single-footer-widget .footer-info-contact span a {
  color: var(--white-color);
}

.footer-section.white-bg .single-footer-widget .footer-info-contact span a:hover {
  color: var(--white-color) !important;
}

.footer-section.white-bg .single-footer-widget .footer-quick-links li {
  color: var(--white-color);
}

.footer-section.white-bg .single-footer-widget .footer-quick-links li a {
  color: var(--white-color);
}

.footer-section.white-bg .single-footer-widget .footer-quick-links li a:hover,.footer-section.white-bg .single-footer-widget .footer-quick-links li a:hover svg {
  color: var(--second-main-color) !important;
}

.footer-section.white-bg .single-footer-widget .footer-news h4 {
  color: #444444;
  border-left: 1px dashed #444444;
  line-height: 1.5;
}

.footer-section.white-bg .single-footer-widget .footer-news span {
  color: #7a7e9a;
  border-left: 1px dashed #443f69;
}

.footer-section.white-bg .copyright-area .copyright-area-content p {
  color: var(--white-color);
}

.footer-section.white-bg .copyright-area .copyright-area-content p a {
  color: #444444;
}

.footer-section.white-bg .copyright-area .copyright-area-content p a:hover {
  color: var(--second-main-color);
}

.footer-section.white-bg .copyright-area .copyright-area-content ul li {
  color: var(--white-color);
}

.footer-section.white-bg .copyright-area .copyright-area-content ul li a {
  color: var(--second-main-color);
  font-weight: bold;
}


.footer-section a svg {
  margin-right: 5px;
  opacity: 0;
  margin-left: -20px;
  transition: .3s;
  margin-top: -2px;
}

.single-footer-widget {
  margin-bottom: 50px;
}

.single-footer-widget h2 {
  font-size: 20px;
  color: #ffffff;
  margin: 0 0 30px 0;
}

.single-footer-widget .footer-info-contact {
  position: relative;
  margin-bottom: 16px;
}

.single-footer-widget .footer-info-contact:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-info-contact i {
  color: var(--main-color);
  position: absolute;
  left: 0;
  top: -6px;
  font-size: 16px;
}

.single-footer-widget .footer-info-contact svg {
  color: var(--second-main-color);
  font-size: 1.4rem;
}

.single-footer-widget .footer-info-contact p {
  font-size: 18px;
  color: var(--second-main-color);
  font-weight: 400;
  margin: 0 0 10px 0;
  padding-left: 5px;
}

.single-footer-widget .footer-info-contact span {
  font-size: 14px;
  /* color: #d0ccee; */
  font-weight: 400;
}

.single-footer-widget .footer-info-contact span a {
  font-size: 14px;
  /* color: #d0ccee; */
  font-weight: 400;
  transition: 0.5s;
  padding-left: 2rem;
}

.single-footer-widget .footer-info-contact span a:hover {
  color: var(--main-color);
}

.single-footer-widget .footer-quick-links {
  padding-left: 0;
  margin-bottom: 0;
  /* columns: 2; */
}

.single-footer-widget .footer-quick-links li {
  list-style-type: none;
  padding-bottom: 8px;
  /* color: #d0ccee; */
}

.single-footer-widget .footer-quick-links li:last-child {
  padding-bottom: 0;
}

.single-footer-widget .footer-quick-links li a {
  display: inline-block;
  /* color: #d0ccee; */
  font-size: 16px;
  font-weight: 400;
  transition: .5s;
  text-transform: capitalize;
}

.single-footer-widget .footer-quick-links li a:hover {
  color: var(--main-color);
  transition: 0.5s;
  /* letter-spacing: 1px; */
}
.single-footer-widget .footer-quick-links li a:hover svg{
  opacity: 1;
  margin-left: 0;
}
.single-footer-widget .footer-news {
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
}

.single-footer-widget .footer-news:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-news img {
  position: absolute;
  left: 0;
  border-radius: 5px;
}

.single-footer-widget .footer-news h4 {
  font-size: 16px;
  color: #ffffff;
  
  font-weight: 400;
  margin: 0 0 12px 0;
  border-left: 1px dashed #443f69;
  padding-left: 20px;
  line-height: 1.5;
}

.single-footer-widget .footer-news span {
  font-size: 15px;
  color: #7a7e9a;
  border-left: 1px dashed #443f69;
  padding-left: 35px;
  position: relative;
}

.single-footer-widget .footer-news span::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: var(--main-color);
  left: 22px;
  top: 5px;
  border-radius: 30px;
  transition: 0.5s;
}

.copyright-area {
  padding-bottom: 10px;
}

.copyright-area .copyright-area-content {
  /* padding-top: 20px; */
  /* border-top: 1px solid #3f3c58; */
}

.copyright-area .copyright-area-content p {
  /* color: #d0ccee; */
  /* font-size: 14px; */
}

.copyright-area .copyright-area-content p a {
  /* color: #d0ccee; */
  display: inline-block;
  font-weight: 600;
}

.copyright-area .copyright-area-content p a:hover {
  color: var(--main-color);
}

.copyright-area .copyright-area-content ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.copyright-area .copyright-area-content ul li {
  display: inline-block;
  /* color: #d0ccee; */
  /* font-size: 14px; */
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.copyright-area .copyright-area-content ul li a {
  display: inline-block;
  /* color: #d0ccee; */
}

.copyright-area .copyright-area-content ul li a:hover {
  color: var(--main-color);
}

.copyright-area .copyright-area-content ul li::before {
  content: '';
  position: absolute;
  top: 5px;
  right: -13px;
  width: 1px;
  height: 14px;
  background-color: var(--main-color);
}

.copyright-area .copyright-area-content ul li:last-child {
  margin-right: 0;
}

.copyright-area .copyright-area-content ul li:last-child::before {
  display: none;
}

.copyright-area .copyright-area-content ul li:first-child {
  margin-left: 0;
}

@media (max-width:568px) {
  .copyright-area .copyright-area-content ul {
    text-align: center;
  }
  .copyright-area .copyright-area-content ul li {
    margin-top: 5px;
  }
}