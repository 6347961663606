.blog-section {
  /* margin-bottom: 12rem; */
}

.blog-card {
  position: relative;
  box-shadow: 0px 5px 10px #e5e5e5;
  background-color: #f3f3f362;
  border-radius: 1rem;
}
.blog-card img {
  width: 100%;
  height: 400px;
  border-radius: 1rem 1rem 0 0;
}

.blog-content {
  width: 100%;
  padding: 2rem;
  /* position: absolute; */
  right: 0;
  bottom: 0;
  background-color: #f3f3f362;
  border-radius: 0 0 1rem 1rem;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}

.blog-content a {
  transition: 0.3s;
  font-size: 1.5rem;
  font-weight: 500;
}
.blog-content a:hover {
  color: var(--black-color);
} 
.blog-content .home-blog-heading{
  display: inline-block;
  height: 70px;
  overflow-y: auto;
}
.home-blog-heading::-webkit-scrollbar {
  width: 3px;
}

.home-blog-heading::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.home-blog-heading::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #525151; 
}
.blog-content p {
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: 120px;
  overflow-y: auto;
  padding-right: 10px;
}
.blog-content svg {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 3rem;
  color: var(--white-color);
  background-color: var(--second-main-color);
  border-radius: 1rem 0 1rem 0;
}
.blog-img span {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  /* background-color: var(--second-main-color); */
  background-color: var(--main-color);
  color: var(--white-color);
  border-radius: 0 1rem 0 1rem;
}
@media (max-width: 568px) {
  .blog-content {
    /* margin-top: -50px; */
    /* float: right; */
    padding: 1rem;
    /* position: relative; */
  }
  .blog-section {
    margin-bottom: 0;
  }
}
