.donar-card img {
  border-radius: 50%;
}

.donar-card {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 10px #e5e5e5;
  padding: 20px 40px;
  border-radius: 1rem;
}

.donor-slider .swiper-slide {
  padding: 10px;
}


.content-card{
  box-shadow: 0px 1px 5px #0000009a;
  padding: 10px 20px;
  /* height: 45%; */
  border-radius: 1rem;
}

.imageee{
  border-radius: 1rem;
  ;
}

.donor-date-section p{
  margin-bottom: 0;
}
.w-60{
  width: 60%;
}
@media (max-width: 568px) {
  .donar-card {
    flex-direction: column;
    padding: 1rem;
  }

  .donar-card .content{
    margin-top: 1rem;
    padding: 0 !important;
  }
  .donar-card p{
    max-height: 96px !important;
    overflow: auto !important;
    margin: 0;
    padding-right: 10px;
  }
}

