/*blog-section-style-1*/
.photo-gallery-style-1 .main-text {
    margin-bottom: .5rem;
}
.photo-gallery-style-1 .main-sub-text {
    font-style: italic;
}

.react-photo-gallery--gallery img {
    object-fit: cover;
}