.academy-image img {
    height: 60vh;
}

.academy-heading::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 13%;
    transform: translateX(-50%);
    width: 200px;
    height: 2px;
    background-color: var(--second-main-color);
}

.academy-main-heading::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 6%;
    transform: translateX(-50%);
    width: 165px;
    height: 2px;
    background-color: var(--second-main-color);
}

.academy-card {
    padding: 1rem;
    height: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 1rem;
    box-shadow: 0px 5px 10px #00000038;
    background: var(--white-color);

}

.academy-card img {
    border-radius: 10px;
    margin-bottom: 10px;
}

.academy-card p {
    /* display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden; */
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.academy-card .academy-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
}

.academy-card img {
    cursor: pointer;
}

.academy-card .academy-content h4 {
    cursor: pointer;
    margin-bottom: 10px;
}

.academy-card .academy-content p {
    height: 100px;
    overflow-y: auto;
    padding-right: 10px;
}

.academy-card .academy-content h4 {
    width: 100%;
    height: 55px;
    /* font-size: 1.5rem; */
    font-size: 1.5rem;
    overflow-y: auto;
    padding-right: 10px;
}

.academy-card .academy-content h4::-webkit-scrollbar,
.academy-card .academy-content::-webkit-scrollbar {
    width: 3px;
}

.academy-card .academy-content h4::-webkit-scrollbar-track,
.academy-card .academy-content::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.academy-card .academy-content h4::-webkit-scrollbar-thumb,
.academy-card .academy-content::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #525151;
}

.page-dummy {
    cursor: pointer;
    width: fit-content;
}

@media (max-width: 575.98px) {
    .academy-heading::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 29%;
        transform: translateX(-50%);
        width: 200px;
        height: 2px;
        background-color: var(--second-main-color);
    }

    .academy-main-heading::before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 23%;
        transform: translateX(-50%);
        width: 165px;
        height: 2px;
        background-color: var(--second-main-color);
    }
}