.story-section {
  background-color: var(--main-color);
  position: relative;
}
.story-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  top: -1px;
  left: 0;
  background: url("../../../assets/images/banner/s-top.png");
  background-size: contain;
  
}
.story-section::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: -1px;
  left: 0;
  background: url("../../../assets/images/banner/s-bottom.png");
  background-size: contain;
}
.story-section .main-heading h2,
.story-section .main-heading p {
  color: var(--white-color);
}
.story-section-btn:hover {
  background-color: var(--white-color);
  color: var(--main-color);
}
.story-card {
  position: relative;
  background: var(--white-color);
  border-radius: 1rem;
  overflow: hidden;
  height: 550px;
  box-shadow: 0px 5px 5px #0000009a;
}

.storie-img {
  height: 50%;
  text-align: center;
}
.storie-img img {
  width: 60%;
  height: 90%;
  margin-top: 1.5rem;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
}
.story-content {
  padding: 1rem;
  height: 50%;
}
.story-content .default-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  font-size: 1.5rem;
  border-radius: 1rem 0 0 0;
}
.story-content .default-btn:hover {
  background: var(--second-main-color) !important;
  color: var(--black-color) !important;
}
.story-content .s-des {
  font-size: 1.2rem;
  /* color: var(--second-main-color); */
  color: var(--main-color);
}

.story-bottom-box {
  position: absolute;
  bottom: -20%;
  transform: translateY(20%);
  background-color: var(--second-main-color);
  padding: 1rem;
  border-radius: 1rem;
  height: 500px;
  z-index: 1;
}
.story-bottom-box .main-heading-left::before {
  background-color: var(--white-color);
}
.story-bottom-box .default-btn {
  background-color: var(--main-color);
  color: var(--white-color);
  padding: 5px 10px;
}
.story-bottom-box .default-btn:hover{
    background-color: var(--white-color);
    color: var(--main-color);
}

.story-bottom-box p {
  margin-bottom: 3rem;
  line-height: 2rem;
}
.main-heading-2::before {
  background-color: var(--main-color) !important;
}.story-section 
.swiper-pagination .swiper-pagination-bullet:hover:active{
  background: transparent;
}
.story-section .swiper-pagination .swiper-pagination-bullet {
  border-color: var(--white-color) !important;
}

.story-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background-color: var(--white-color)!important;
}

@media (max-width:1399px) {
  .story-content{
    height: 55%;
  }
  .storie-img {
    height: 45%;
  }
  .story-card {
    height: 550px;  
  }
}
@media (min-width:1000px) and (max-width:1199px) {
  .story-card {
    height: 440px;
  }
  .story-content{
    height: 55%;
  }
  .storie-img {
    height: 45%;
  }
}
@media (max-width: 568px) {
  .story-section::after,
  .story-section::before {
    background-repeat: no-repeat;
  }
  .storie-img {
    height: 50%;
  }
  .story-bottom-box{
    position: static;
    transform: translate(0);
  }
  .story-section::after{
    bottom: inherit;
  }
}
