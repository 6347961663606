.not-found-img{
    max-width: 1100px;
    max-height: 700px;
    object-fit: cover;
    margin: 0 auto;
}
.NotFoundPage-inner-section .default-btn{
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: -175px;
    margin-bottom: 4rem;
}
@media (max-width:568px) {
    .not-found-img{
        width: 100%;
        height: auto;
    }
    .NotFoundPage-inner-section .default-btn{
        margin-top: -20px;
        margin-bottom: 1rem;
    }
    
}