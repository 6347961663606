.modal-btn,
.modal-btn:hover,
.modal-btn:active,
.modal-btn:focus {
  background: none;
  color: var(--black-color);
  border: none;
  padding: 0;
}
.modal-btn:hover {
  color: var(--main-color);
}
.modal-btn:focus {
  box-shadow: none !important;
}
.modal-body form label {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.modal-body .form-field {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.modal-body form input {
  border-radius: 10px;
  padding: 1rem;
  border: 1px solid #e5e5e5;
}
.modal-content form span {
  color: #ff0000 !important;
}
.download-form {
  /* display: none; */
  color: rgb(80, 157, 80);
}
