.donation-card {
  padding: 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px #00000057;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.donation-card-2{
  justify-content: flex-start;
}
.donate-inner-section h1 a,
.donation-card h2 a {
  transition: 0.3s;
}
.donate-inner-section h1 a:hover,
.donation-card h2 a:hover {
  color: var(--second-main-color);
}
.donation-card li {
  position: relative;
  margin-bottom: 10px;
}
.donation-card li::before {
  content: "";
  position: absolute;
  left: -1rem;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--black-color);
}
/* 
.donation-card .default-btn{
    justify-self: flex-end;
} */
.content-table-2{
  box-shadow: none;
  
}
.content-table-2 thead tr {
  background-color: #e5e5e5;
  color: var(--black-color);
}
.content-table-2 tbody tr:nth-of-type(even) {
  background-color: #f3f3f38c;
}
.content-table-2 tbody tr:last-of-type {
  border-bottom: 1px solid #929191cc;
}
 .bank-table {
  overflow: auto;
  /* border: 1px solid #929191cc !important; */
}
.bank-table td,.bank-table tr,.bank-table th, .bank-table tbody{
  border: 1px solid #929191cc !important;
border-width: 1px !important;
} 
.donation-card .main-heading h2,
.sponsor-card .main-heading h2{
  font-size: 1.7rem;
}
