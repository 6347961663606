@media screen and (max-width: 768px) {
  .carousel-caption h2 {
    font-size: 2.5em;
  }

  .carousel-caption h3 {
    font-size: 1.5rem;
  }

  .default-btn,
  .optional-btn {
    font-size: 12px;
    padding: 10px !important;
  }
}

.default-btn,
.optional-btn {
  padding: 10px 20px;
  color: var(--white-color);
  margin-right: 1rem;
  border-radius: 2px;
  transition: 0.5s;
}

.optional-btn {
  background-color: var(--white-color);
  color: var(--main-color);
}

.optional-btn:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.default-btn:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}

.Main-slider {
  width: 100%;
  height: 100vh;
  /* background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url("../../../assets/images/slider-images/nabd-banner.webp") center; */
  /* background: url("../../../assets/images/slider-images/nabd-banner.webp")
    center; */
  background-size: cover;
}

.Main-slider .swiper-slide {
  width: 100% !important;
  padding: 0 !important;
  height: 100%;
}

.Main-slider .swiper-pagination .swiper-pagination-bullet {
  border-color: var(--white-color) !important;
}

.Main-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background: var(--white-color) !important;
}

.Main-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-banner {
  /* margin-top: 3rem;
  color: var(--white-color) !important;
  padding: 2rem;
  background-color: #0000007e;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.682);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.477);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); */
}

.home-banner .b-h2 {
  font-size: 2.1rem;
  color: var(--second-main-color);
  line-height: 3.5rem;
  margin-top: -10px;
  font-weight: 500;
  background-color: #0000007e;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.89);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.477);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* padding: 10px; */
}

.home-banner .b-h1 {
  width: 90%t;
  padding: 5px;
  border-radius: 0.25rem;
  font-size: 1rem !important;
  color: var(--white-color);
  font-weight: 500;
  padding: 12px;
  background-color: #0000007e;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.909);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.477);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin: 0 auto;
}

.home-banner p {
  font-size: 1.1rem;
}

.home-banner ul li {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--white-color);
  padding-right: 2.9rem;
  font-size: 1.1rem;
}

.home-banner ul li:last-child {
  border: none;
  padding: 0;
}

.home-banner ul li span:first-child {
  font-size: 2rem;
}


.banner-content .b-h1 {
  width: 90%;
  padding: 5px;
  border-radius: 0.25rem;
  font-size: 1rem !important;
  color: var(--white-color);
  font-weight: 500;
  padding: 12px;
  background-color: #0000007e;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.909);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.477);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin: 0 auto;
}

.banner-content .b-h2 {
  font-size: 2.1rem;
  color: var(--second-main-color);
  line-height: 3.5rem;
  margin-top: -10px;
  font-weight: 500;
  background-color: #0000007e;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.89);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.477);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 10px;
  text-align: center;
}

@media (max-width: 1399px) {
  .Main-slider {
    height: 115vh;
  }

  .home-banner {
    margin-top: 6rem;
  }

  .home-banner ul li {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .home-banner ul li:first-child {
    padding-left: 1rem;
  }

  .home-banner ul li:last-child {
    padding-left: 2rem;
  }
}

@media (min-width: 1000px) and (max-width: 1199px) {
  .home-banner .b-h2 {
    font-size: 3rem !important;
  }


  .home-banner ul li span:first-child {
    font-size: 1.8rem;
  }

  .home-banner ul li span:last-child {
    font-size: 0.8rem;
  }

  .offcanvas-body .nav-link {
    padding: 2px 5px !important;
  }
}

@media (max-width: 992px) {
  .Main-slider {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .Main-slider p:last-child {
    padding: 10px 0;
    font-size: .9rem !important;
  }

  .home-banner {
    margin: 1rem 0;
  }

  .home-banner .b-h2 {
    font-size: 1.8rem !important;
    line-height: 2.3rem;
  }

  .home-banner .b-h1 {
    padding: 0;
  }

  .home-banner p {
    font-size: 1rem !important;
  }

  .home-banner p:first-child {
    font-size: 1.4rem !important;
  }

  .home-banner ul li {
    border: none;
    margin-bottom: 1rem;
  }

  .home-banner ul li {
    padding-left: 1rem !important;
    border-left: 1px solid var(--white-color) !important;
  }

  .banner-content .b-h2 {
    font-size: 1.1rem;
    line-height: normal;
    width: 95%;
    margin: 0 auto;
  }

  .Main-slider p:last-child {
    font-size: .7rem !important;
    width: 100%;
    text-align: center;
    margin: 1rem auto;
  }

  .home-banner .b-h1 {
    width: 95%;
    margin: 0 auto;
  }

}

/* @media  (max-width: 819px) {
  .Main-slider {
    height: 60vh;
  }
} */
@media (max-width: 448px) {
  .Main-slider {
    height: auto;
  }

  .home-banner p:first-child {
    font-size: 1.1rem !important;
  }


  .home-banner p:last-child {
    font-size: .7rem !important;
  }

  .home-banner {
    margin: 0;
  }

  .main-heading h2 {
    font-size: 2rem !important;
  }

  .Main-slider .swiper-pagination {
    padding-bottom: 10px;
  }

}


.banner-content {
  position: absolute;
  z-index: 2;
  bottom: 45px;

}