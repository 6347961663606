.partner-slider .swiper-slide {
  display: flex;
  height: 150px;
  align-items: center !important;
}
.partner-slider .swiper-slide img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
