.page-title-area {
  position: relative !important;
  z-index: 1;
  /* background-image: url("../../../assets/images/slider-images/2.webp"); */
  /* background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; */
  height: 170px;
}

.page-title-area::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--black-color);
  left: 0;
  top: 0;
  opacity: 1;
  z-index: -1;
}

.page-title-content {
  text-align: center;
  margin-bottom: -45px;
}

.page-title-content h2 {
  color: var(--black-color);
  font-size: 42px;
}
.page-title-content h3 {
  color: var(--black-color);
  margin-bottom: 2rem;
}

.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 50px;
}

.page-title-content ul li {
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 1.3rem;
  margin-left: 10px;
  margin-right: 10px;
}

.page-title-content ul li a {
  display: inline-block;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-title-content ul li a:hover {
  color: var(--second-main-color);
}

.page-title-content ul li::before {
  content: "|";
  position: absolute;
  right: -15px;
  top: 0;
}

.page-title-content ul li:last-child::before {
  display: none;
}
.about-inner-img {
  position: relative;
}
.about-inner-img img {
  width: 85%;
  height: 420px;
  border-radius: 100%;
}
.about-inner-img img:nth-child(2) {
  position: absolute;
  top: 0;
  right: 20px;
  width: 150px;
  height: 150px;
  border: 5px solid var(--white-color);
}
/* .about-inner-year{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: var(--second-main-color);
    color: var(--white-color);
    font-size: 2rem;
    border-radius: 1rem;
} */
/* .about-organisation{
    margin-bottom: -3rem;
} */

.about-page li {
  position: relative;
}
.about-page li::before {
  content: "";
  position: absolute;
  left: -1rem;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--black-color);
}
.award-section-2 img {
  height: 350px;
}
@media (max-width: 992px) {
  .page-title-area {
    height: auto;
  }
}
@media (max-width: 767px) {
  .page-title-area {
    padding-bottom: 50px;
  }
  .page-title-content ul {
    margin: 0;
  }
  .about-inner-img img {
    width: 100%;
    height: 360px;
    margin-bottom: 2rem;
  }
  .page-title-content {
    text-align: right;
  }
  .award-section img {
    margin-bottom: 1rem;
  }
  .award-section-2 img {
    height: auto;
  }
}
