.refund-policy li {
  margin-top: 1rem;
}
.refund-policy ol {
  counter-reset: item;
  padding: 0;
}
.refund-policy ol li {
  display: table;
  counter-increment: item;
  margin-bottom: 1rem;
}

.refund-policy ol li:before {
  content: counters(item, ".") " ";
  font-weight: bold;
  display: table-cell;
  padding-right: 0.6em;
}
