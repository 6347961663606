.service-detail img {
  border-radius: 1rem;
  height: 100%;
}

.service-detail ul {
  padding-left: 0;
  list-style: inherit !important;
  /* display: flex;
  flex-wrap: wrap; */
}

.service-detail li {
  /* position: relative; */
  margin-bottom: 10px;
  /* width: 50%; */
}

/* .service-detail li::before {
  content: "";
  position: absolute;
  left: -1rem;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--black-color);
} */
.as-img {
  width: 100px;
  height: 100px;
  margin-right: 1rem;
}
.as-img img {
  border-radius: 5px;
}
.all-service-card {
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px #e5e5e5;
  padding: 1rem;
}
.as-gallery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.as-img {
  width: 100px;
  height: 100px;
  margin-right: 1rem;
}

.as-img img {
  border-radius: 5px;
}

.all-service-card {
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px #e5e5e5;
  padding: 1rem;
}

.as-gallery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.as-gallery img {
  width: 50%;
  border-radius: 0;
}
.service-inner-card h3,
.service-inner-card img {
  cursor: pointer;
}
.service-inner-content p,
.service-inner-content li{
  text-align: justify;
}
@media (max-width: 568px) {
  .service-detail ul {
    flex-direction: column;
  }
  .service-detail p{
    text-align: justify;
  }
  .service-detail img {
    height: auto !important;
  }
  .service-detail ul li {
    width: 100%;
  }
}
