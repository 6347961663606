.service-section {
  background: url("../../../assets/images/banner/service.png");
  background-size: cover;
  height: 98vh;
}

.s-content {
  border: 1px solid #e5e5e5;
  margin-top: -40px;
  background: var(--white-color);
  position: relative;
  z-index: 1;
  width: 90%;
  border-radius: 0 1rem 1rem 1rem;
  padding: 1rem;
  text-align: left;
}

.s-content p {
  height: 100px;
  overflow-y: auto;
  padding-right: 10px;
}

.s-content h3::-webkit-scrollbar,
p::-webkit-scrollbar {
  width: 3px;
}

.s-content h3::-webkit-scrollbar-track,
p::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.s-content h3::-webkit-scrollbar-thumb,
p::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #525151;
}

/* .carousel .carousel-status{
  display: none !important;
} */
.service-card {
  width: 100%;
}

/* .s-card-img, */
.s-card-img img {
  width: 100%;
  height: 250px;
  border-radius: 1rem 1rem 1rem 0;
}

.s-content h3 {
  width: 100%;
  height: 55px;
  /* font-size: 1.5rem; */
  font-size: 1.5rem;
  overflow-y: auto;
  padding-right: 10px;
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}

.s-content h3 a:hover {
  color: var(--black-color);
}

.main-heading {
  position: relative;
  margin-bottom: 2rem;
}

.main-heading h2 {
  font-size: 2.5rem;
}

.main-heading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 2px;
  background-color: var(--second-main-color);
}

.main-heading-left::before {
  left: 0;
  transform: translateX(0);
  bottom: -10px;
}

.react-multi-carousel-dot--active button,
.swiper-pagination .swiper-pagination-bullet {
  background: transparent !important;
}

.react-multi-carousel-track {
  padding-bottom: 2.8rem !important;
}

.react-multi-carousel-dot button,
.swiper-pagination .swiper-pagination-bullet {
  background-color: initial;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  outline: 0;
  position: relative;
  transition: 0.5s;
}

.swiper-pagination .swiper-pagination-bullet {
  border: 1px solid var(--main-color);
  opacity: 1 !important;
  box-shadow: none !important;
}

.react-multi-carousel-dot button {
  border-color: var(--main-color) !important;
}

.react-multi-carousel-dot button:before,
.swiper-pagination .swiper-pagination-bullet:before {
  border-radius: 50%;
  content: "";
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
  width: 50%;
}

.react-multi-carousel-dot--active button:before,
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background-color: var(--white-color);
}

.react-multi-carousel-dot--active button:before,
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background-color: var(--main-color) !important;
}

@media (max-width: 1399px) {
  .service-section {
    height: auto;
    padding-bottom: 2rem;
  }

  .service-card .s-content {
    height: 250px;
  }
}

@media (min-width: 1000px) and (max-width: 1199px) {
  .service-card .s-content {
    height: 300px;
  }
}

@media (max-width: 568px) {
  .service-section {
    height: auto;
    padding-bottom: 2rem;
  }

  .s-content h4 {
    height: auto;
  }

  .swiper-backface-hidden .swiper-slide {
    padding: 0 1rem;
    /* margin: 0 !important; */
    margin-bottom: 1rem;
  }
}