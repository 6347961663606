.donate-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  align-items: center;
}
.donate-btn > a:first-child,
.default-btn {
  /* background-color: var(--second-main-color); */
  background-color: var(--main-color);
  color: var(--white-color);
  font-weight: bold;
  font-size: 1.1rem;
  padding: 3px 10px;
  border-radius: 0.25rem !important;
  color: var(--white-color) !important;
}
.donate-btn a:nth-child(2) svg {
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: 0.25rem;
  color: var(--white-color);
  background-color: var(--main-color);
}
.donate-btn ul {
  margin: 0;
  padding: 0;
}
.donate-btn ul li a svg,
.nav-sm-icon svg {
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: 0.25rem;
  color: var(--white-color);
  background-color: var(--main-color);
  margin-top: 0.8rem;
  box-shadow: 0 0 5px #e5e5e565;
}
@media (max-width:992px) {
  .donate-btn ul li a svg,
  .nav-sm-icon svg {
    box-shadow: none !important;
    margin: 0;
  }
}