.back-to-top {
  z-index: 2 !important;
  right: -10px !important;
  bottom: -14px !important;
}
.myBtn {
  border-radius: 7px;
  width: 32px;
  height: 32px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--main-color);
  position: relative !important;
  z-index: 1 !important;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 3px solid var(--main-color);
  box-shadow: 0 0 5px #e5e5e565;
}
.myBtn:hover {
  background-color: transparent;
  border-color: var(--second-main-color);
}
.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.6rem;
  color: var(--white-color);
  z-index: 999;
}

.myBtn:hover .arrow {
  color: var(--second-main-color);
}
