.approach-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  top: -1px;
  left: 0;
  background: url("../../../assets/images/banner/s-top1.png");
  background-size: contain;
}
.vm-left {
  position: relative;
  padding-left: 5rem;
  height: 100%;
}

.vm-left img {
  position: relative;
  border-radius: 100%;
  height: 95%;
  width: 90%;
  margin-top: 3rem;
}
.vm-heading {
  position: absolute;
  bottom: 100px;
  left: 0;
  color: var(--second-main-color);
  font-size: 3rem;
}
.vm-left-content {
  /* position: absolute;
  left: -50px;
  top: -70px; */
  background-color: var(--white-color);
  padding: 2rem;
  border-radius: 1rem;
  /* width: 50%; */
  box-shadow: 0px 5px 20px #e5e5e5;
}

.vm-left-content ul{
  padding: 0;
}
.vm-left-content li {
  position: relative;
}
/* .vm-left-content li::before {
  content: "";
  position: absolute;
  left: -1rem;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--black-color);
} */
.approach-section {
  background-color: var(--second-main-color);
}
.approach-section .main-heading::before {
  background-color: var(--white-color);
}

.approach-card {
  background-color: var(--white-color);
  padding: 5rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 20px #494949b5;
}
.approach-card-2 {
  color: var(--white-color);
  background-color: var(--main-color);
}
.approach-card-2 h2 {
  color: var(--white-color);
}
.approach-card-2-main {
  position: relative;
  z-index: 1;
}
.approach-card-1 {
  margin-left: 4rem;
}
.approach-card-1,
.approach-card-2-main {
  margin-right: -4rem;
}
@media (min-width:993px) {
  .approach-card {
    height: 320px;
    padding: 1rem 1.5rem;
  }
  .approach-card p{
    font-size: .8rem;
  }
  .approach-card-1 {
    margin-left: 3rem;
  }
  .approach-card-1,
  .approach-card-2-main {
    margin-right: -3rem;
  }
}
@media (min-width:1150px) {
  .approach-card {
    height: 380px;
    padding: 1rem 1.7rem;
  }
  .approach-card-1 {
    margin-left: 3rem;
  }
  .approach-card-1,
  .approach-card-2-main {
    margin-right: -3rem;
  }
}
@media (min-width:1250px) {
  .approach-card {
    height: 370px;
    padding: 1rem 2rem;
    text-size-adjust: initial;
  }
  .approach-card-1 {
    margin-left: 3rem;
  }
  .approach-card-1,
  .approach-card-2-main {
    margin-right: -3rem;
  }
}
@media (min-width:1400px) {
  .approach-card {
    height: 400px;
  }
  .approach-card p{
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .vm-left-content {
    position: static;
    width: 100%;
  }
  .vm-left {
    padding: 0;
    position: static;
  }
  .vm-left img {
    margin-top: 0;
    width: 95%;
    height: 100%;
    position: static;
  }
  .approach-card {
    padding: 4rem 5rem;
    font-size: 0.9rem;
    height: 600px;
  }
  .approach-card-1,
  .approach-card-2-main {
    margin: 0;
    margin-bottom: -2rem;
  }
  .approach-section {
    margin-bottom: 4rem;
  }
  .vision-misson p{
    text-align: justify;
  }
}
