.card-section {
  background-color: #e5e5e5;
}
.main-card-section {
  padding: 1rem 2rem;
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px #000000;
}
.three-section-card {
  padding: 1rem;
  height: 100%;
  /* padding: 1rem 2rem; */
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px #00000038;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
}
/* .three-section-card .default-btn{
  display: inline-block;
  margin-top: 2rem;
} */
.t-card-img img {
  border-radius: 1rem 1rem 1rem 0;
  margin-bottom: 1rem;
  height: 330px;
}
/* .three-section-card-1 .default-btn{
  margin-top: 1.15rem;
} */
.ts-content {
  /* display: flex;
  flex-direction: column;
  align-content: space-between; */
  /* height: 100%; */
  height: 80%;
  margin-bottom: 3rem;
}
.ts-icon {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  height: 50px;
  background-color: var(--main-color);
  padding: 10px;
  border-radius: .25rem;
}
.ts-icon svg {
  font-size: 2.5rem;
  margin-right: 1rem;
  color: var(--white-color);
}
.ts-icon img {
  margin-right: 1rem;
}
.ts-icon h3 {
  margin-top: 5px;
  color: var(--white-color);
}
/* .three-section-card-1{
    border-right: 1px solid #e5e5e5;
} */
/* .three-section-card-2 {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.three-section-card-3 {
  border-top: 1px solid #e5e5e5;
  border: none;
} */
/* .three-section-card-4 {
  border-left: 1px solid #e5e5e5;
  position: relative;
} */
.three-section-card-4 svg {
  font-size: 20rem;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #18191912;
  transform: rotate(10deg);
  transform: translate(-50%, -50%);
}
.three-section-card-4 h3 {
  font-weight: 300;
  text-align: center;
  margin-top: 2rem;
}
.three-section-card-4 h3 span {
  color: var(--main-color);
  font-weight: bold;
}
/* .three-section-card .default-btn:hover {
  background-color: var(--main-color);
  color: var(--white-color);
} */
@media (max-width: 1399px) {
  .main-card-section {
    padding: 1rem;
  }
  .three-section-card h2 {
    font-size: 1.8rem;
  }
  .three-section-card p {
    font-size: 1rem;
  }
  .three-section-card-4 svg {
    font-size: 18rem;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
}
@media (max-width: 568px) {
  /* .three-section-card {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    padding: 2rem 1rem;
  }
  .three-section-card-3 {
    border-bottom: none;
  } */
}
