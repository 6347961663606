.partner-card{
    box-shadow: 0 0px 30px rgb(0 0 0 / 10%);
    border-radius: 1rem;
    padding: 10px;
    text-align: center;
}
.partner-card img{
    border-radius: 1rem;
    height: 150px;
    width: 100%;
    margin-bottom: 1rem;
    object-fit: contain;
}