body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-content p {
  color: #fff;
  margin-bottom: 0;
  font-size: 25px;
}

.preferred-partner p {
  font-size: 25px;
  margin-bottom: 0;
}

.inner-banner .header-content p {
  color: #000;
}

@media (max-width:900px) {
  .header-content p {
    color: #000;
    font-size: .7rem !important;
  }

  .Main-slider p:last-child {
    font-size: 0;
    margin-right: -16px;
  }

  .preferred-partner p {
    font-size: 15px;
  }
}