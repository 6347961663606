.office-card {
  position: relative;
  height: 100%;
  margin: 0 2rem;
  /* margin-bottom: 3rem; */
  background-color: var(--white-color);
  box-shadow: 0 0px 30px rgb(0 0 0 / 10%);
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  transition: 0.5s;
  /* margin-top: 0; */
  top: 0;
}
.office-card img {
  /* position: absolute; */
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.office-card .office-content {
  /* position: absolute; */
  /* bottom: -20px;
  left: 0; */
  /* background-color: var(--white-color); */
  /* padding: 1rem; */
  width: 100%;
  /* height: 50%; */
  /* text-align: center; */
  /* box-shadow: 0 0px 30px rgb(0 0 0 / 10%); */
  /* border-radius: 1rem; */
  margin-top: 1rem;
  text-transform: capitalize;
}
.office-card .office-content h2 {
  font-size: 1rem;
  font-weight: 600 !important;
  /* height: 60%; */
}
.office-card .office-content p {
  font-weight: 500;
  color: var(--second-main-color);
}
.office-card:hover .office-content {
  bottom: 0;
}
.office-card:hover {
  top: -1rem;
}
@media (max-width: 568px) {
  .office-card {
    margin: 0;
    margin-bottom: 2rem;
  }
  .office-card .office-content {
    padding: 1rem;
    /* bottom: -25px; */
  }

  .office-card .office-content h6 {
    font-size: 0.9rem;
  }
}
