.award-section img {
  border-radius: 1rem;
  width: 100%;
}
.sarojini-downloads {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  /* columns: 2 300px; */
  padding-top: 1rem;
}
.sarojini-downloads li {
  width: 50%;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  font-weight: 500;
}
.sarojini-downloads li a {
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
}
.sarojini-downloads li a:hover {
  color: var(--second-main-color);
}
.sarojini-downloads li a svg {
  font-size: 1.4rem;
  margin-left: 2rem;
}
.sd-card {
    background-color: #ffffff;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.162);
  padding: 20px;
  border-radius: 1rem;
  height: 100%;
}
.sd-card a{
    text-decoration: underline !important;
    transition: .3s;
    text-underline-offset: 5px;
}
.sd-card a:hover{
    color: var(--second-main-color);
}
.sd-card li {
  position: relative;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.sd-card li::before {
  content: "";
  position: absolute;
  left: -1rem;
  top: 0.5rem;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: var(--black-color);
}
@media (max-width: 568px) {
  .sarojini-downloads li {
    width: 100%;
  }
}
