/* .volunteer-section form,
.volunteer-section .form-field,
.volunteer-section .form-field input,
.volunteer-section .form-field textarea{
    
} */
.volunteer-section form button{
    border: none;
}
.volunteer-section .form-field{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}
.volunteer-section .form-field label{
    margin-left: .5rem;
    margin-bottom: .5rem;
}
.volunteer-section .form-field input,
.volunteer-section .form-field select,
.volunteer-section .form-field textarea{
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #d1d0d0;
    background-color: #f4f4f4;
}
.contact-info{
    padding: 2rem;
    background-color: var(--second-main-color);
    border-radius: 1rem;
}
.contact-info .main-heading-left::before{
    background-color: var(--white-color);
}
.contact-info h5,
.contact-info a{
    color: var(--white-color);
}