@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

:root {
  --color-dark: #333333;
  --color-linkedin: #0072b1;
  --color-facebook: #1877F2;
  --color-instagram: #E1306C;
  --color-whatsapp: #25D366;
  --color-twitter: #1DA1F2;
  --color-youtube: #FF0000;
  --color-light: #eeeeee;
  --main-color: #0c0fb3;
  --second-main-color: #FF7903;
  --white-color: #fff;
  --black-color: #000000;
  --font-theme: 'Poppins', sans-serif;
}

html,
body {
  font-family: var(--font-theme) !important;
  color: var(--color-dark);
  -webkit-font-smoothing: antialiased !important;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

.theme-dark body {
  background: var(--black-color);
  color: var(--white-color) !important;
}

.theme-dark .service-section {
  /* background: var(--black-color); */
}

.theme-dark .main-title,
.theme-dark .three-section-card-4 h3,
.theme-dark .three-section-card-4 h3 span,
.theme-dark .main-text {
  color: var(--white-color) !important;
}

.switch-box {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 20px;
  z-index: 2 !important;
  border-radius: 50px;
}

.video-section iframe {
  width: 100%;
  height: 300px;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black-color);
}

.inner-banner {
  position: relative;
  margin-bottom: 8rem;
}

.inner-banner .nav-container nav {
  background-color: var(--white-color) !important;
}

.inner-banner .nav-container nav a {
  color: var(--black-color);
}

@media (max-width:448px) {
  .inner-banner {
    margin-bottom: 6rem;
  }
}

.video-card {
  padding: 1rem 10px;
  border-radius: 1rem;
  background-color: var(--white-color);
}

.stories-description {
  max-height: 96px !important;
  overflow: auto !important;
}