
.terms-conditions ol {
  counter-reset: item;
  padding: 0;
}

.terms-conditions ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 1rem;
}

.terms-conditions ol > li:before {
  content: counters(item, ".") " ";
  font-weight: bold;
  display: table-cell;
  padding-right: 0.6em;
}
.terms-conditions ul{
    margin-top: 1rem;
}

.terms-conditions ul li{
    position: relative;
    margin-bottom: 10px;
}
.terms-conditions ul li::before{
    content: "";
    position: absolute;
    left: -1rem;
    top: .55rem;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--black-color);
}