.event-detail-card img{
    border-radius: 1rem;
}
.event-heading{
    font-size: 1.3rem;
}
.event-heading svg{
    color: var(--second-main-color);
    margin: 0 10px;
}
@media (max-width:568px) {
    .event-heading{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: .5rem 0;
    }
}