.imgblogcontent {
    width: 100%;
  }
  .blog_heading h4 {
    font-size: 1.75rem !important;
    font-weight: bolder;
  }
  .blogdetailsdatefont1 {
    font-weight: bolder;
  }
  .blogcommentusername {
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 0;
  }
  .profile_comment_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .uname {
    display: flex;
    flex-direction: column;
  }
  .box-comment {
    margin-bottom: 0.5rem;
  }
  .commentbox {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(80, 80, 111, 0.156);
  }
  .commentbox:first-child {
    padding-top: 0px;
  }
  .submitbtn {
    width: 10% !important;
  }
  .blogimage1 {
    height: 400px !important;
  }
  .social-medialink a {
    color: var(--theme-color-blue);
  }
  .social-medialink a:hover {
    color: var(--theme-color-orange);
  }
  .ancorblog a {
    text-decoration: none;
    color: #20395c;
  }
  .blog-readmore1 a {
    color: #e58411;
  }
  .commentcountno a {
    text-decoration: none !important;
  }
  .commentcountno {
    padding: 10px;
    background-color: rgba(165, 165, 165, 0.152);
    width: 70px;
    border-radius: 5px;
  }
  .reply-btn {
    margin-bottom: 0;
    color: var(--theme-color-blue);
    font-weight: 600;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all ease-in-out 0.3s;
    gap: 5px;
  }
  .reply-btn:hover {
    color: var(--theme-color-orange);
  }
  .reply-btn p {
    margin-bottom: 0;
  }
  .commet-btn {
    width: 20% !important;
  }
  .blogdetailsdatefont {
    font-size: 14px;
    margin-bottom: 0;
  }
  .commentbox:last-child {
    border-bottom: none;
  }
  .blog-details-skeleton .react-loading-skeleton {
    padding: 20px;
  }
  @media screen and (max-width: 768px) {
    .commet-btn {
      width: 100% !important;
    }
  }
  