.governing-card {
  /* height: 300px; */
}
.governing-card img {
  height: 50%;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 0.2rem;
}
.governing-card .governing-content {
  padding: 1rem;
  text-align: center;
  text-transform: capitalize;
  /* height: 32%; */
}
.governing-card .governing-content h5 {
  font-size: 1rem;
  height: 60%;
}
.governing-card .governing-content h6 {
  color: var(--main-color);
}



.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  width: 100%;
  border-radius: .25rem;
  overflow: hidden; 
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: var(--main-color);
  color: #ffffff;
  /* text-align: left; */
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-radius: 0 0 .25rem .25rem !important;
  border-bottom: 2px solid var(--main-color);
}




@media (max-width: 568px) {
  .governing-card .governing-content {
    padding: 1rem 5px;
    height: 50%;
  }

  .governing-card .governing-content h6 {
    font-size: 0.9rem;
  }
  .governing-card {
    height: 220px;
  }
  .governing-card .governing-content h5 {
    height: 50%;
  }
  .governing-card img{
    height: 60%;
  }
}
