.newsletter-section {
  background:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url("../../../assets/images/newsletter.webp")
    no-repeat;
  background-size: cover;
  height: auto;
  display: flex;
  align-items: center;
  padding: 5rem 0;
}
.newsletter-box {
  background-color: var(--white-color);
  /* position: relative; */
  padding: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  align-items: center;
  /* box-shadow: 0px 10px 10px #e5e5e5; */
}
.newsletter-box svg {
  height: 100px;
  width: 100px;
  padding: 10px;
  font-size: 3rem;
  color: var(--black-color);
  background-color: var(--second-main-color);
}
.newsletter-box h4,
.newsletter-box h2 {
  color: var(--black-color);
  /* margin-bottom: 0; */
  font-size: 1.5rem;
}

.news-input {
  display: flex;
  flex-direction: column;
  /* align-items: baseline; */
  color: var(--black-color);
  flex-wrap: wrap;
}
.news-input label {
  font-size: 1.4rem;
  margin: 10px 0;
  /* align-self: baseline; */
  width: 20%;
}
.news-input input {
  border: none;
  background-color: transparent;
  margin-left: 15px;
  width: 80%;
  margin-bottom: 0;
  border-bottom: 1px solid var(--black-color);
}
.news-input button {
  border: none;
}
.news-input button:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}
.news-input label{
  display: flex;
  align-items: center;
}
.news-input label span{
  font-size: 1rem;
  color: #ff0000 !important;
  padding-top: 6px;
  padding-left: 5px;
}
/* @media (max-width: 1400px) {
  .newsletter-section {
    height: 60vh;
  }
}
@media (max-width: 1300px) {
  .newsletter-section {
    height: 70vh;
  }
}
@media (max-width: 1224px) {
  .newsletter-section {
    height: 150vh;
  }
}
@media (max-width: 992px) {
  .newsletter-section {
    height: 90vh;
  }
} */
@media (max-width: 568px) {
  .news-input label {
    width: 25%;
    font-size: 1.2rem;
  }
}
