.download-card {
  background-color: #ffffff;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.162);
  padding: 20px;
  border-radius: 1rem;
  margin-bottom: 2rem;
  height: 100%;
}
.download-card h4 {
  text-transform: capitalize;
  color: var(--second-main-color);
}

.download-card a {
  color: var(--black-color);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.download-card a span {
  width: 90%;
  font-weight: bold;
  transition: .3s;
}
.download-card a:hover {
  color: var(--second-main-color);
}

.download-card a svg {
  color: var(--second-main-color);
  font-size: 1.5rem;
  margin-top: -4px;
  margin-left: 0.5rem;
}

@media (max-width: 568px) {
  .download-card a {
    padding: 0.5rem 0;
  }
}
