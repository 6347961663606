.contact-section form,
.contact-section .form-field,
.contact-section .form-field input,
.contact-section .form-field textarea{
    width: 100%;
}
.contact-section form button{
    border: none;
}
.contact-section .form-field{
    margin-bottom: 1rem;
}
.contact-section .form-field label{
    margin-left: .5rem;
    margin-bottom: .5rem;
}
.contact-section .form-field input,
.contact-section .form-field textarea{
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #d1d0d0;
    background-color: #f4f4f4;
}
.contact-info{
    padding: 2rem;
    background-color: #000 !important;
    border-radius: 1rem;
}
.contact-info .main-heading-left h2,
.contact-info h3{
    color: var(--second-main-color) !important;
}
.contact-info .main-heading-left::before{
    background-color: var(--second-main-color) !important;
}
.contact-info a{
    color: var(--white-color);
    transition: .3s;
}.contact-info a:hover{
    color: var(--second-main-color) !important;
}
.form-field label{
    display: flex;
    align-items: center;
}
.form-field label span{
    color: #ff0000 !important;
    padding-top: 6px;
    padding-left: 5px;
}
