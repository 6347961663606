.event-section {
    background: url("../../../assets/images/banner/service.png");
    /* position: relative; */
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
}
.event-section > img {
  width: 50%;
  height: 100%;
  display: none;
  /* position: absolute;
    left: 0;
    top: 0; */
}
.event-section .container {
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}
.event-section .container > .row {
  border: 5px solid #e5e5e5;
  border-radius: 1rem;
  padding: 2rem;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.74);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
/* .event-left {
  background: rgba(0, 0, 0, 0.465);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 2rem;
  border-radius: .7rem 0 0 .7rem; 
} */
.event-left h2,
.event-left h3 {
  color: var(--white-color);
}
.event-left p {
  color: var(--white-color);
}
/* .event-left-a{
    margin-top: 8rem !important;
    display: inline-block;
} */
.event-right {
  padding: 1rem;
}
.event-right .event-card {
  height: 200px;
}
.event-right .event-card {
  display: flex;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 0.7rem;
  overflow: hidden;
}
.event-right .event-card img {
  height: 100%;
  width: 35%;
}
.event-right-content {
  padding: 1rem;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}
.event-right-content div {
  transition: 0.4s;
  font-size: 1.5rem;
  font-weight: 500;
}
.event-right-content div:hover {
  color: var(--second-main-color);
}
.event-right-content span {
  align-self: flex-end;
  margin-bottom: .7rem;
  /* color: var(--second-main-color); */
  color: var(--main-color);
}
.event-right .react-multi-carousel-dot button {
  border-color: var(--white-color) !important;
}

.event-right .react-multi-carousel-dot--active button:before {
  background-color: var(--white-color) !important;
}
.swiper-pagination .swiper-pagination-bullet:hover:active{
  background: transparent;
}
.event-right .swiper-pagination .swiper-pagination-bullet {
  border-color: var(--white-color) !important;
}

.event-right .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background-color: var(--white-color)!important;
}
.event-right-content a{
  font-size: 1.2rem;
  font-weight: 500;
}
/* @media (max-width: 1400px) {
  .event-section {
    height: 110vh;
  }
}
@media (max-width: 1300px) {
  .event-section {
    height: 115vh;
  }
}
@media (max-width: 1224px) {
  .event-section {
    height: 150vh;
  }
}
@media (max-width: 1124px) {
  .event-section {
    height: 150vh;
  }
}
@media (max-width: 992px) {
  .event-section {
    height: 190vh;
  }
}
@media (max-width: 820px) {
  .event-section {
    height: 70vh;
  }
} */
@media (max-width: 568px) {
  /* .event-section {
    height: 115vh;
  } */
  .event-section > img {
    width: 100%;
    height: 50%;
  }
  .event-left {
    border-radius: 0.7rem 0.7rem 0 0;
  }
  .event-right {
    padding: 1rem 0;
  }
  .event-right .event-card {
    height: 150px;
  }
  .event-left-a {
    margin-top: 0.5rem !important;
  }
  .event-section .container > .row {
    padding: 1rem;
  }
}
