.completed-project-inner-section .project-card h3,
.completed-project-inner-section .project-card p {
    height: 100px;
    overflow-y: auto;
    padding-right: 10px;
}

.completed-project-inner-section .project-card h3 {
    font-size: 1.2rem !important;
}

.completed-project-inner-section .project-card img {
    height: 250px;
    width: 100%;
}

.completed-project-inner-section .project-card h3::-webkit-scrollbar {
    width: 3px;
}

p.completed-project-inner-section .project-card h3:-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.completed-project-inner-section .project-card h3::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #525151;
}

.completed-project-inner-section .project-card {
    border: 1px solid #e5e5e5;
    border-radius: 1rem;
    box-shadow: 0px 5px 10px #e5e5e5;
    padding: 1rem;
}