/*about-style-1*/
.about-style-1 .main-text {
  margin-bottom: 0.5rem;
}

.main-text {
  font-weight: 300;
  font-size: 1.5rem !important;
}

.main-title {
  font-weight: 500;
}

/*about-style-2*/
.about-style-2 .main-text {
  margin-bottom: 0.5rem;
}

/* .about-style-1 ul li {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--white-color);
  padding: 2rem;
  font-size: 1.1rem;
  min-width: 300px;
  border-right: 1px solid #e5e5e5;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px #e5e5e5;
  background-color: #f3f3f362;
}
.about-style-1 ul li:last-child {
  border: none;
  padding: 0;
}
.about-style-1 ul li span:first-child {
  font-size: 2rem;
  color: var(--main-color);
} */
.about-num-section p:first-child {
  font-weight: 500;
  font-size: 2.5rem !important;
}
.about-num-section img {
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.about-num-section-bottom .main-section {
  padding: 1rem 2rem !important;
  /* border: 3px solid var(--main-color); */
  /* border-radius: 1rem; */
  /* height: 100%; */
}
.about-num-section-bottom .main-section .col-lg-4 {
  height: 100%;
}
.about-num-section-bottom .main-section li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--main-color);
  padding: 1rem 0;
  height: 100%;
  /* padding-right: 3rem; */
  /* height: 300px; */
  border: 3px solid var(--main-color);
  border-radius: 1rem;
  box-shadow: 0px 5px 10px #e5e5e5;
  /* width: 33%; */
}
.about-num-section-bottom .main-section li img {
  margin-bottom: 1rem;
  width: 250px;
  height: 110px !important;
}

.about-num-section-bottom .main-section li:last-child {
  /* padding: 0; */
  /* border: none; */
  /* padding-right: 0; */
}
.about-num-section-bottom .main-section li span {
  font-size: 2rem !important;
  color: var(--main-color);
  font-weight: 500;
}
.about-num-section-bottom .main-section li p {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--black-color);
}
@media (max-width: 1200px) {
  .about-num-section-bottom .main-section li p {
    font-size: 1.2rem;
  }
  .about-num-section-bottom .main-section li {
    margin-bottom: 2rem;
  }
  /* .about-num-section-bottom .main-section li {
    border: none;
    border-bottom: 1px solid var(--main-color);
  }
  .about-num-section-bottom .main-section li:last-child {
    border: none;
  } */
}
@media (max-width: 992px) {
  .about-num-section-bottom .main-section .col-lg-4 {
    height: auto;
    margin-bottom: 2rem;
  }
  .about-num-section-bottom .main-section li img {
    height: auto !important;
    margin: 0;
    width: 200px;
  }
}
@media (max-width: 568px) {
  .about-style-1 ul li {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .about-style-1 ul {
    justify-content: center;
    padding: 0;
  }
  .main-heading h2:last-child,
  .main-text {
    font-size: 1.2rem !important;
  }
}
